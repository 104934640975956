'use client';
import React from 'react';
import Link from 'next/link';
import Button from '@/ui/Button';
import Tag from '@/ui/Tag';
import { Stack, Row } from '@/ui/primatives';
import { IUser, IMemberPlan } from '@/types';
import * as Styled from './styled';

import { IPlanOption } from '@/config/planOptions';
import BillingCycleSwitch from './BillingCycleSwitch';

export const PlanOption: React.FC<
  React.HtmlHTMLAttributes<HTMLDivElement> & {
    option: IPlanOption;
    selected?: boolean;
    disabled?: boolean;
    mode?: 'display' | 'select';
    billingCycle: 'monthly' | 'annual';
    buttonText?: string;
    onSelect?: () => void;
    highlight?: boolean;
    baseRedirectUrl?: string;
  }
> = ({
  option,
  selected,
  disabled,
  mode = 'select',
  billingCycle,
  buttonText = 'SELECT',
  highlight,
  onSelect,
  baseRedirectUrl = '/billing/checkout',
  ...rest
}) => {
  const displayPrice = billingCycle === 'monthly' ? option.display_price_monthly : option.display_price_annual;
  return (
    <Styled.AnimatedGradientWrapper $enabled={!disabled} $glow className={highlight ? 'on' : 'off'} {...rest}>
      <Styled.PlanOption selected={selected} disabled={disabled}>
        <Styled.PlanOptionBody>
          <Row gap={20} justifyContent="space-between">
            <Stack gap={10} justifyContent="space-between" style={{ height: '100%' }}>
              <h4>{option.name}</h4>
              <div>
                <Tag variant="info">
                  <b>{displayPrice}</b>
                </Tag>
                {billingCycle === 'annual' && option.planLevel > 0 && (
                  <>
                    <br />
                    <sub>&nbsp;&nbsp;*Billed annually</sub>
                  </>
                )}
              </div>
            </Stack>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={option.image_path} alt={option.name} height={100} style={{ borderRadius: 5 }} />
          </Row>
          <h6>{option.subtitle}</h6>
          {mode === 'select' && (
            <Stack>
              {selected ? (
                <Button variant="secondary" className="selected" onClick={() => selected && onSelect?.()}>
                  {selected ? 'YOUR PLAN' : buttonText}
                </Button>
              ) : (
                <Link
                  href={
                    option.planLevel === 0
                      ? `${baseRedirectUrl} `
                      : `${baseRedirectUrl}?plan_id=${option.id}&frequency=${billingCycle}`
                  }
                >
                  <Button
                    variant={disabled ? 'basic' : option.cta_variant}
                    onClick={onSelect}
                    style={{ width: '100%' }}
                  >
                    {buttonText}
                  </Button>
                </Link>
              )}
            </Stack>
          )}
          <ul>
            {option.features?.public?.map((feature, i) => (
              <li className={feature.isAddition ? 'add' : ''} key={i}>
                {feature.title}
              </li>
            ))}
          </ul>
          <ul>
            {option.features?.private?.map((feature, i) => (
              <li className={feature.isAddition ? 'add' : ''} key={i}>
                {feature.title}
              </li>
            ))}
          </ul>
        </Styled.PlanOptionBody>
      </Styled.PlanOption>
    </Styled.AnimatedGradientWrapper>
  );
};

const PlanOptionsWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Styled.PlanOptions>{children}</Styled.PlanOptions>;
};

const PlanOptions: React.FC<{
  currentUser?: IUser;
  currentPlan?: IMemberPlan;
  availablePlans?: IPlanOption[];
  frequency?: 'monthly' | 'annual';
  onSelect?: () => void;
}> = ({ currentUser, currentPlan, availablePlans, frequency: defaultBillingCycle = 'monthly', onSelect }) => {
  const options = availablePlans?.sort((a, b) => a.planLevel - b.planLevel);
  const [billingCycle, setBillingCycle] = React.useState<'monthly' | 'annual'>(defaultBillingCycle);

  return (
    <Stack gap={25}>
      <Row justifyContent="center" style={{ zIndex: 1 }}>
        <BillingCycleSwitch mode={billingCycle} onToggle={setBillingCycle} style={{ width: 450 }} />
      </Row>

      <PlanOptionsWrapper>
        {options?.map((option) => {
          // TODO: This is a hack
          const isDowngrade = (currentPlan?.storage_quota_gb || 0) > option.storage_quota_gb;
          const isUpgrade = (currentPlan?.storage_quota_gb || 0) < option.storage_quota_gb;

          const currentPlanLevel = options.find((option) => option.name === currentPlan?.name)?.planLevel;

          const isNextUpgrade = currentPlanLevel && option.planLevel === currentPlanLevel + 1;

          const highlight = isNextUpgrade || (option.planLevel === 1 && currentPlanLevel !== 1);
          // const highlight = currentPlan?.name === 'Hacker' && option.name === 'Team'  option.name === 'Hacker';
          return (
            <div key={option.name}>
              {' '}
              {/* this div wrapper is required to keep safari from stretching the options vertically outside the container */}
              <PlanOption
                option={option}
                selected={currentPlan?.name === option.name}
                highlight={highlight}
                billingCycle={billingCycle}
                disabled={isDowngrade}
                baseRedirectUrl={currentUser ? '/billing/checkout' : '/register'}
                buttonText={currentPlan ? (isDowngrade ? 'DOWNGRADE' : 'UPGRADE') : option.cta_text}
                onSelect={onSelect}
              />
            </div>
          );
        })}
      </PlanOptionsWrapper>
    </Stack>
  );
};

export default PlanOptions;
