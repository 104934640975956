import styled from 'styled-components';
import { colors, breakPoints, mixins } from '@/styles';

const tickCircleSvg = `<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm7.072 3.21l4.318-5.398-.78-.624-3.682 4.601L4.32 7.116l-.64.768 3.392 2.827z" fill="blue"></path></svg>`;
const plusCircleSvg = `<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zM7 11V8H4V7h3V4h1v3h3v1H8v3H7z" fill="green"></path></svg>`;

export const PlanOptions = styled.div<{}>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* justify-content: stretch; */
  /* align-items: stretch; */
  gap: 25px;
  & > * {
    /* flex: 1; */
    /* border: 1px dashed lime; */
  }
  button {
    width: 100%;
    font-weight: 600;
  }

  @media (max-width: ${breakPoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${breakPoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const PlanOption = styled.div<{ selected?: boolean; disabled?: boolean }>`
  border: 2px solid
    ${({ selected, disabled }) => (selected || disabled ? colors.secondary.blue1 : colors.secondary.blue2)};
  border-radius: 5px;
  padding: 25px;
  gap: 25px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ disabled }) => (disabled ? colors.secondary.lightGray1 : colors.primary.white)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  filter: grayscale(${({ disabled }) => (disabled ? 1 : 0)});
  h4 {
    color: ${colors.secondary.blue2};
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  ul li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 0.5em;
  }

  ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf8,${tickCircleSvg}');
  }

  ul li.add::before {
    background-image: url('data:image/svg+xml;utf8,${plusCircleSvg}');
  }

  @media (max-width: ${breakPoints.mobile}) {
    padding: 15px;
  }
`;

export const PlanOptionBody = styled.div<{}>`
  gap: 25px;
  display: flex;
  flex-direction: column;
  /* border: 1px dashed magenta; */
  height: 100%;

  & > * {
    /* border: 1px dashed lime; */
    flex: 1;
  }

  h6 {
    flex: 1;
    align-items: flex-start;
  }
`;

export const AnimatedGradientWrapper = styled.div<{ $glow?: boolean; $borderWidth?: number; $enabled: boolean }>`
  height: 100%;
  position: relative;

  & > * {
    position: relative;
  }
  ${({ $glow, $enabled }) => $enabled && mixins.animatedGradientWrapper($glow)};
`;
